import React from "react"
import { Container, Segment } from 'semantic-ui-react'
import NavBar from "../NavBar"
import ToolList from "./ToolList"

const Tools = () => {
  return (
    <Container className="tool-list">
      <NavBar />
      <Segment>
        <h3>Tools</h3>
        <ToolList />
      </Segment>
    </Container>
  )
}

export default Tools
