import React from 'react'
import { NavLink } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

const NavBar = () => {
  return (
    <Menu inverted>
      <Menu.Item as={NavLink} exact to="/" name="home" />
      <Menu.Item as={NavLink} exact to="/contributors" name="contributors" />
      <Menu.Item as={NavLink} exact to="/resources" name="resources" />
      <Menu.Item as={NavLink} exact to="/tools" name="tools" />
      <Menu.Item as={NavLink} exact to="/templates" name="templates" />
      <Menu.Item as={NavLink} exact to="/about" name="about" />
    </Menu>
  )
}

export default NavBar
