import React from "react"
import { roles, skills, projectTypes } from '../data-fields'


export const RolesRow = props => {
  const { data, title } = props
  const els = data.map(item => {
    const field = roles.filter(p => p.id == item)[0]
    return (field && field.name) ? field.name : item
  })
  return (
    <>
      <span>{els.join(", ")}</span>
    </>
  )
}
export const SkillsRow = props => {
  const { data, title } = props
  const els = data.map(item => {
    const field = skills.filter(p => p.id == item)[0]
    return (field && field.name) ? field.name : item
  })
  return (
    <>
      <span>{title}: </span>
      <span>{els.join(", ")}</span>
    </>
  )
}

export const ProjectTypesRow = props => {
  const { data, title } = props
  const els = data.map(item => {
    const field = projectTypes.filter(p => p.id == item)[0]
    return (field && field.name) ? field.name : item
  })
  return (
    <>
      <span>{title}: </span>
      <span>{els.join(", ")}</span>
    </>
  )
}
