import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import Home from './Components/Home'
import About from './Components/About/About'
import SearchResults from './Components/Search/SearchResults'
import FilterResults from './Components/Filter/FilterResults'
import Contributors from './Components/Contributor/Contributors'
import Contributor from './Components/Contributor/Contributor'
import Resources from './Components/Resource/Resources'
import Resource from './Components/Resource/Resource'
import Tools from './Components/Tool/Tools'
import Tool from './Components/Tool/Tool'
import Templates from './Components/Template/Templates'
import Template from './Components/Template/Template'

const App = () => {

  return (
    <BrowserRouter>
      <div className="App">
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} exact />
        <Route path="/search" component={SearchResults} />
        <Route path="/category/:category" component={FilterResults} />
        <Route path="/contributors" component={Contributors} />
        <Route path="/contributor/:name" component={Contributor} />
        <Route path="/resources" component={Resources} />
        <Route path="/resource/:name" component={Resource} />
        <Route path="/tools" component={Tools} />
        <Route path="/tool/:name" component={Tool} />
        <Route path="/templates" component={Templates} />
        <Route path="/template/:name" component={Template} />
      </div>
    </BrowserRouter>
  )
}

export default App
