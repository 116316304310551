
export const roles = [
  { id: 'language-worker', name: 'Language worker' },
  { id: 'language-learner', name: 'Language learner' },
  { id: 'linguist', name: 'Linguist' },
  { id: 'community-volunteer', name: 'Community volunteer' },
  { id: 'project-manager', name: 'Project manager' },
  { id: 'media-and-communications', name: 'Media and communications' },
  { id: 'teacher', name: 'Teacher' },
]

export const skills = [
  { id: 'administration', name: 'Administration' },
  { id: 'archiving', name: 'Archiving' },
  { id: 'linguistics', name: 'Linguistics' },
  { id: 'media-production', name: 'Media production' },
  { id: 'project-management', name: 'Project management' },
  { id: 'resource-production', name: 'Resource production' },
  { id: 'teaching', name: 'Teaching' },
  { id: 'technology', name: 'Technology' }
]

export const projectTypes = [
  { id: 'building-community-strength', name: 'Building community strength'},
  { id: 'language-enrichment', name: 'Language enrichment'},
  { id: 'resource-development', name: 'Resource development'},
  { id: 'teaching-language', name: 'Teaching language'}
]

export const toolActivityTypes = [
  { id: 'administration', name: 'Administration' },
  { id: 'archiving', name: 'Archiving' },
  { id: 'collection-management', name: 'Collection management' },
  { id: 'communication', name: 'Communication' },
  { id: 'documentation', name: 'Documentation' },
  { id: 'language-learning', name: 'Language learning' },
  { id: 'making-resources', name: 'Making resources' },
  { id: 'media-production', name: 'Media production' },
  { id: 'project-management', name: 'Project management' },
  { id: 'teaching-language', name: 'Teaching language' }
]
