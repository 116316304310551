import React from "react"
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import { List, ListItem } from 'semantic-ui-react'
import { Link } from "react-router-dom"

const ToolsList = props => {
  const { ToolActivityType } = props
  
  let option = { collection: "tools" }
  useFirestoreConnect(option)
  const tools = useSelector(state => state.firestore.ordered.tools)
  
  let toolList

  if (tools) {
    // Make a unique array of just the tool names
    // Link to detail page by name rather than id
    // So we can match multiple entries by name
    // Also nicer for sharing
    let toolNames = [...new Set(tools.map(tool => tool.name))]
    toolList = toolNames.map(toolName => {
      return (
        <ListItem key={toolName} as={Link} to={`/tool/${encodeURI(toolName)}`}>
            {toolName}
        </ListItem>
      )
    })
  }
  return (
    <List className="tools content-list padded">
      {toolList}
    </List>
  )
}

export default ToolsList
