import React from "react"
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import { Container, Segment } from 'semantic-ui-react'
import NavBar from "../NavBar"
import SearchInput from "../Search/SearchInput"

const FilterResults = props => {

  const { match } = props
  useFirestoreConnect(["tools", "templates", "resources"])
  const tools = useSelector(({ firestore: { data } }) => data.tools)
  const templates = useSelector(({ firestore: { data } }) => data.templates)
  const resources = useSelector(({ firestore: { data } }) => data.resources)

  // Are there URL options (set in App.js eg /map/:options*)
  // if (match.params.category) {
  //   const params = match.params.options.split('/')
  // }

  let toolsFiltered = []
  let templatesFiltered = []
  let resourcesFiltered = []

  if (isLoaded(tools) && isLoaded(templates) && isLoaded(resources) && match.params.category) {

    // build an array of names (Tool detail is filtered by name)
    for (var [key, tool] of Object.entries(tools)) {
      if (tool['tool_activity_types']){
      if (tool['tool_activity_types'].includes(match.params.category)) {
        toolsFiltered.push(tool.name)
      }}
    }

    // array of names and keys (Template detail is queried by id)
    for (var [key, template] of Object.entries(templates)) {
      if (template['tool_activity_types']){
      if (template['tool_activity_types'].includes(match.params.category)) {
        templatesFiltered.push(template.name)
      }}
    }

    // build an array of names (Resource detail is filtered by name)
    for (var [key, resource] of Object.entries(resources)) {
      if (resource['tool_activity_types']){
      if (resource['tool_activity_types'].includes(match.params.category)) {
        resourcesFiltered.push(resource.name)
      }}
    }
  }

  // Make tools unique
  toolsFiltered = [...new Set(toolsFiltered)]

  return (
    <Container>
      <NavBar />

      <Segment>
        Tools and templates for {match.params.category}
      </Segment>

      {toolsFiltered.length > 0 &&
        <Segment>
          <h4>Tools</h4>
          <ul>
            {
              toolsFiltered.map((name, i) => {
                return (
                  <li key={i}>
                    <Link to={`/tool/${encodeURI(name)}`}>
                      {name}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </Segment>
      }

      {templatesFiltered.length > 0 &&
        <Segment>
          <h4>Templates</h4>
          <ul>
            {
              templatesFiltered.map((name, i) => {
                return (
                  <li key={i}>
                    <Link to={`/template/${encodeURI(name)}`}>
                      {name}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </Segment>
      }

      {resourcesFiltered.length > 0 &&
        <Segment>
          <h4>Resources</h4>
          <ul>
            {
              resourcesFiltered.map((name, i) => {
                return (
                  <li key={i}>
                    <Link to={`/resource/${encodeURI(name)}`}>
                      {name}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </Segment>
      }

    </Container>
  )
}

export default withRouter(FilterResults)
