import React from "react"
import { Container, Segment } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import NavBar from "../NavBar"
import ToolDetail from "./ToolDetail"

const Tool = () => {
  let { id } = useParams()
  return (
    <Container>
      <NavBar />
      <Segment>
        <ToolDetail id={id} />
      </Segment>
    </Container>
  )
}

export default Tool
