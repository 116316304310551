import React from "react"
import { Container, Segment } from 'semantic-ui-react'
import NavBar from "../NavBar"
import ContributorList from "./ContributorList"

const Contributors = () => {
  return (
    <Container>
      <NavBar />
      <Segment>
        <h3>Contributors</h3>
        <ContributorList />
      </Segment>
    </Container>
  )
}

export default Contributors
