import React from "react"
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { Button, Container, Image, Segment } from 'semantic-ui-react'
import FieldRender from './FieldRender'


const ToolDetail = () => {
  // Get the name of the tool from URL params (automatically decodesURI)
  let { name } = useParams()

  let option = { collection: "tools" }
  // where: ["name", "==", decodeURI(name)]
  // doc: id
  // }
  useFirestoreConnect(option)
  const tools = useSelector(state => state.firestore.ordered.tools)

  // We only want a subset of tools that match names
  const filterTools = (data, name) => {
    let arr = {}
    if (data) {
      for (var [key, item] of Object.entries(data)) {
        if (item.name === name) {
          arr = { ...arr, ...{ [key]: item } }
        }
      }
    }
    return arr
  }


  let toolsFiltered = filterTools(tools, name)
  // And we want the fields from all matching entries to be grouped
  const groupData = data => {
    let arr = {
      "website": [],
      "uses": [],
      "tool_activity_types": [],
      "how_well": [],
      "increased": [],
      "what_better": [],
      "training": [],
      "how_easy": [],
      "cost": [],
      "requirements": [],
      "alternatives": [],
      "image": [],
      "file": []
    }
    for (var [key, item] of Object.entries(data)) {
      for (var [i, j] of Object.entries(arr)) {
        if (item[i]) arr[i].push({ value: item[i], contributed_by: item["contributed_by"] })
      }
    }
    return arr
  }
  let fieldsGrouped = groupData(toolsFiltered, name)
  console.log("fieldsGrouped", fieldsGrouped)

  // Render the data
  if (!isLoaded(tools)) {
    return <>loading</>
  }
  if (isLoaded(tools)) {
  }
  return (
    <Container className="tool-detail">
      <h2>{name}</h2>
      <div className="tool-content">
        <FieldRender data={fieldsGrouped} field={"website"} title={"Website"} />
        <FieldRender data={fieldsGrouped} field={"uses"} title={"Main uses"} />
        <FieldRender data={fieldsGrouped} field={"tool_activity_types"} title={"Types of activities"} />
        <FieldRender data={fieldsGrouped} field={"how_well"} title={"How well it works"} />
        <FieldRender data={fieldsGrouped} field={"increased"} title={"How it increases language use"} />
        <FieldRender data={fieldsGrouped} field={"what_better"} title={"Possible improvements"} />
        <FieldRender data={fieldsGrouped} field={"training"} title={"Training required"} />
        <FieldRender data={fieldsGrouped} field={"how_easy"} title={"How easy is it to use"} />
        <FieldRender data={fieldsGrouped} field={"cost"} title={"Cost"} />
        <FieldRender data={fieldsGrouped} field={"requirements"} title={"System requirements"} />
        <FieldRender data={fieldsGrouped} field={"alternatives"} title={"Alternatives"} />

        {/* Note the double map - first for grouped posts, then for multiple images per post */}
        {fieldsGrouped.image && fieldsGrouped.image.length > 0 &&
          <Segment className="media">
            {fieldsGrouped.image.map((entry, i) => (
              entry.value.map(file => (
                <a className="open-image" href={file.src} key={file.src} target="_blank">
                  <Image
                    key={file.src}
                    src={file.src}
                    alt={file.title}
                    className="resp"
                    size="medium" />
                  <Button circular icon='external alternate' className="open-image-icon" />
                </a>
              ))
            ))}
          </Segment>
        }
        {fieldsGrouped.file && fieldsGrouped.file.length > 0 &&
          <Segment>
            {fieldsGrouped.file.map((entry, i) => (
              entry.value.map(file => (
                <div key={file.src} className="pdf-download">
                  <a href={file.src}>Download {file.title}</a>
                </div>
              ))
            ))}
          </Segment>
        }
      </div>
    </Container>
  )
}

export default ToolDetail
