import React from "react"
import { Container, Segment } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import NavBar from "../NavBar"
import TemplateDetail from "./TemplateDetail"
import hash from "object-hash"

const Template = () => {
  let { name } = useParams()
  let name_hash = hash(name)
  let option = { collection: "templates", where: ["name", "==", name], storeAs: name_hash }
  useFirestoreConnect([option])
  const templates = useSelector(state => state.firestore.ordered[name_hash]);
  let template = isLoaded(templates) ? templates[0] : null

  return (
    <Container>
      <NavBar />
      <Segment>
        <TemplateDetail template={template} />
      </Segment>
    </Container>
  )
}

export default Template
