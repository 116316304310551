import React from "react"
import { connect } from 'react-redux'
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import { List, ListItem, Segment } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { RolesRow, SkillsRow, ProjectTypesRow } from './FieldRows'

const ContributorList = props => {

  let option = { collection: "contributors", storeAs: "contributorList" }
  useFirestoreConnect([option])
  const contributors = useSelector(state => state.firestore.ordered["contributorList"])

  let orgs = {}
  let orgEls

  if (contributors) {
    // array of just orgs, make it easier to group people
    let orgs = contributors.map(contributor => {
      return (contributor.org)
    })
    // make unique, and alphabetical
    orgs = [...new Set(orgs.sort())]

    // now, go over the org list, and find the contributors for each org
    orgEls = orgs.map((org, i) => {
      const orgPeople = contributors.filter(contributor => {
        return contributor.org === org
      })
      const orgPeopleEls = orgPeople.map(contributor => {
        let id = contributor.id
        return (
          <ListItem key={contributor.id} className="contributor" as={Link} to={`/contributor/${encodeURI(contributor.name)}`}>
            <p className="name">
              {contributor.name}
            </p>
            <p className="meta">
              {contributor.roles &&
                <RolesRow title="Roles" data={contributor.roles} />
              }
            </p>
          </ListItem>
        )
      }
      )
      // make the els
      return (
        <Segment className="org" key={i}>
          <h3>{org}</h3>
          <List className="contributors content-list padded">
            {orgPeopleEls}
          </List>
        </Segment>
      )
    })
  }

  return (
    <div>
      {orgEls}
    </div>
  )
}
const mapStateToProps = state => ({
  searchTerm: state.yaale.searchTerm
})

export default connect(mapStateToProps)(ContributorList)
