import React from "react"
import { Container, Segment } from 'semantic-ui-react'
import NavBar from "../NavBar"
import TemplateList from "./TemplateList"

const Templates = () => {
  return (
    <Container>
      <NavBar />
      <Segment>
        <h3>Templates</h3>
        <TemplateList />
      </Segment>
    </Container>
  )
}

export default Templates
