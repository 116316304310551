import React, { Component, createRef } from "react"
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import _ from 'lodash'
import { Button, Input } from 'semantic-ui-react'
import { setSearchTerm } from '../../Store/actions'

class SearchInput extends Component {
  inputRef = createRef()
  state = {
    searchTerm: ''
  }

  componentDidMount = () => {
    // Update the input if there's search term in redux
    const { searchTerm } = this.props
    if (searchTerm) this.setState({ searchTerm: searchTerm })
    // Save search text in redux state
    this.debounceSavesearchName = _.debounce(event => {
      this.props.setSearchTerm(event.target.value)
    }, 500);
    // this.inputRef.current.focus()
  }
  handleChange = event => {
    event.persist()
    this.debounceSavesearchName(event)
    this.setState({ searchTerm: event.target.value })
  }
  handleKeyPress = event => {
    const { link } = this.props
    if (event.key === 'Enter') {
      if (link) this.props.history.push(link)
    }
  }
  doSearch = () => {
    const { link } = this.props
    this.props.setSearchTerm(this.state.searchTerm)
    if (link) this.props.history.push(link)
  }

  clearSearch = () => {
    this.setState({ searchTerm: "" })
    this.props.setSearchTerm("")
  }

  render() {
    const { searchTerm } = this.props
    return (
      <>
        <Input
          className={"search-input"}
          value={this.state.searchTerm}
          placeholder='Search...'
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          ref={this.inputRef} />

       <Button icon='search' circular color="brown" size="mini" onClick={this.doSearch} />
       <Button icon='delete' circular basic size="mini" onClick={this.clearSearch} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  searchTerm: state.yaale.searchTerm
})
const mapDispatchToProps = dispatch => ({
  setSearchTerm: term => {
    dispatch(setSearchTerm(term))
  },
})
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchInput))
