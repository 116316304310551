import React from "react"
import { Container, Segment } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import NavBar from "../NavBar"
import ResourceDetail from "./ResourceDetail"
import hash from "object-hash"

const Resource = () => {
  let { name } = useParams()
  let name_hash = hash(name)
  let option = { collection: "resources", where: ["name", "==", name], storeAs: name_hash }
  useFirestoreConnect(option)
  const resources = useSelector(state => state.firestore.ordered[name_hash]);
  let resource = isLoaded(resources) ? resources[0] : null

  return (
    <Container>
      <NavBar />
      <Segment>
        <ResourceDetail resource={resource} />
      </Segment>
    </Container>
  )
}

export default Resource
