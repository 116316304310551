import React from "react"
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import { Link } from "react-router-dom"
import { Button } from 'semantic-ui-react'

const ContributorIcon = props => {
  const { id } = props

  let option = { collection: "contributors", doc: id }
  useFirestoreConnect(option)
  const contributors = useSelector(state => state.firestore.data.contributors)

  if (contributors && contributors[id]) {
    let name = contributors[id].name
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

    return (
      <Button 
      circular 
      className="contributed-by"
      as={Link} 
      to={`/contributor/${name}`}>
        {initials}
      </Button>
    )
  } else {
    return false
  }

}
export default ContributorIcon
