import React from "react"
import { Button, Container, Segment, Image } from 'semantic-ui-react'
import Linkify from 'react-linkify'

const TemplateDetail = props => {
  let { template } = props

  const displayEl = (data, title) => {
    if (data) {
      return (
        <Segment>
          <h4>{title}</h4>
          <p>{data}</p>
        </Segment>
      )
    }
  }

  if (template) {
    return (
      <Container>
        <h2>{template.name}</h2>
        <div className="template-content">
          <Linkify>
            {displayEl(template.website, "Website")}
            {displayEl(template.uses, "Main Uses")}
            {displayEl(template.software, "Software")}
          </Linkify>

          {template.image &&  template.image.length > 0 &&
            <Segment className="media">
              {template.image.map(file => (
                <a className="open-image" href={file.src} key={file.src} target="_blank">
                  <Image
                    key={file.src}
                    src={file.src}
                    alt={file.title}
                    className="resp"
                    size="medium" />
                  <Button circular icon='external alternate' className="open-image-icon"/>
                </a>
              ))}
            </Segment>
          }
          {template.file &&  template.file.length > 0 &&
            <Segment>
              {template.file.map(file => (
                <div key={file.src} className="pdf-download">
                  <a href={file.src}>Download {file.title}</a>
                </div>
              ))}
            </Segment>
          }

        </div>
      </Container>
    )
  } else {
    return <p> loading </p>
  }
}

export default TemplateDetail
