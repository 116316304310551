import React from "react"
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import { List, ListItem } from 'semantic-ui-react'
import { Link } from "react-router-dom"

const ResourcesList = props => {

  let option = { collection: "resources", storeAs: "resourceList" }
  useFirestoreConnect([option])
  const resources = useSelector(state => state.firestore.ordered["resourceList"])

  let resourcesList
  
  if (resources) {
    resourcesList = resources.map(resource => {
      return (
        <ListItem key={resource.id} as={Link} to={`/resource/${encodeURI(resource.name)}`}>
            {resource.name}
        </ListItem>
      )
    })
  }

  return (
    <List className="resources content-list padded">
      {resourcesList}
    </List>
  )
}

export default ResourcesList
