import React from "react"
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { Link } from "react-router-dom"

import { Container, Segment } from 'semantic-ui-react'
import NavBar from "../NavBar"
import SearchInput from "./SearchInput"

const SearchResults = props => {
  const { searchTerm } = props
  const searchPattern = searchTerm.toLowerCase()

  useFirestoreConnect(["contributors", "tools", "templates", "resources"])
  const contributors = useSelector(({ firestore: { data } }) => data.contributors)
  const tools = useSelector(({ firestore: { data } }) => data.tools)
  const templates = useSelector(({ firestore: { data } }) => data.templates)
  const resources = useSelector(({ firestore: { data } }) => data.resources)

  const loadingMessage = (!isLoaded(contributors) || !isLoaded(tools) || !isLoaded(templates)) ? 'loading data' : ''

  let contributorsFiltered = []
  let toolsFiltered = []
  let templatesFiltered = []
  let resourcesFiltered = []

  // array of names and keys (Contributor detail is queried by id)
  if (isLoaded(contributors) && contributors) {
    for (var [key, contributor] of Object.entries(contributors)) {
      if (contributor.name.toLowerCase().match(searchPattern)) {
        contributorsFiltered.push(contributor.name)
      }
    }
  }

  // build an array of names (Tool detail is filtered by name)
  if (isLoaded(tools) && tools) {
    for (var [key, tool] of Object.entries(tools)) {
      if (tool.name.toLowerCase().match(searchPattern)) {
        toolsFiltered.push(tool.name)
      }
    }
  }

  // array of names and keys (Template detail is queried by id)
  if (isLoaded(templates) && templates) {
    for (var [key, template] of Object.entries(templates)) {
      if (template.name.toLowerCase().match(searchPattern)) {
        templatesFiltered.push(template.name)
      }
    }
  }

  // array of names and keys (Template detail is queried by id)
  if (isLoaded(resources) && resources) {
    for (var [key, resource] of Object.entries(resources)) {
      if (resource.name.toLowerCase().match(searchPattern)) {
        resourcesFiltered.push(resource.name)
      }
    }
  }

  // Make tools unique
  contributorsFiltered = [...new Set(contributorsFiltered)]
  toolsFiltered = [...new Set(toolsFiltered)]

  return  (
    <Container>
      <NavBar />
      <Segment>
        <SearchInput />
      </Segment>
      <Segment>
        <h3>Search results</h3>
        {loadingMessage}
      </Segment>

      { contributorsFiltered.length > 0 &&
      <Segment>
        <h4>Contributors</h4>
        <ul>
          {
            contributorsFiltered.map((name, i) => {
            return (
              <li key={i}>
                <Link to={`/contributor/${name}`}>
                  {name}
                </Link>
              </li>
            )})
          }
        </ul>
      </Segment>
      }

      { toolsFiltered.length > 0 &&
      <Segment>
        <h4>Tools</h4>
        <ul>
          {
          toolsFiltered.map((name, i) => {
            return (
              <li key={i}>
                <Link to={`/tool/${name}`}>
                  {name}
                </Link>
              </li>
            )})
          }
        </ul>
      </Segment>
      }

      { templatesFiltered.length > 0 &&
      <Segment>
        <h4>Templates</h4>
        <ul>
          {
          templatesFiltered.map((name, i) => {
            return (
              <li key={i}>
                <Link to={`/template/${name}`}>
                  {name}
                </Link>
              </li>
            )})
          }
        </ul>
      </Segment>
      }

      { resourcesFiltered.length > 0 &&
      <Segment>
        <h4>Resources</h4>
        <ul>
          {
          resourcesFiltered.map((name, i) => {
            return (
              <li key={i}>
                <Link to={`/resource/${name}`}>
                  {name}
                </Link>
              </li>
            )})
          }
        </ul>
      </Segment>
      }

        {contributorsFiltered.length === 0 &&
        toolsFiltered.length === 0 &&
        templatesFiltered.length === 0 &&
        resourcesFiltered.length === 0 &&
          <Segment>
              <p>No results</p>
          </Segment>
        }



    </Container>
  )
}

const mapStateToProps = state => {
  return {
    searchTerm: state.yaale.searchTerm
  }
}

export default connect(
  mapStateToProps
)(SearchResults)
