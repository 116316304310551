import React from "react"
import {Container, Segment} from 'semantic-ui-react'
import NavBar from "./NavBar"
import SearchInput from "./Search/SearchInput"
import FilterInput from "./Filter/FilterInput"

const Home = () => {

    return (
        <Container className="home-page">
            <div className="round-logos">
                <div className="round-logo fla"></div>
                <div className="round-logo ila"></div>
            </div>
            <NavBar/>
            <div className="home-header"></div>
            <div className="title">
                <Container>
                    <h1>Yaale: Tools for language work</h1>
                </Container>
            </div>

            <Segment>
                <p>Yaale shares information about tools and templates which language workers make and use. Contact First
                    Languages Australia to contribute.</p>
                <p className="pronunciation"><em>Yaale</em> is pronounced 'yar-le', with a long 'a’ like the ‘ar’ in ‘far' and shot 'e' as in ‘egg'.
                    It means ‘speak' in Waka Waka and has been provided by project researcher Annalee Pope.</p>
                
            </Segment>
            <Segment>
                <h3>Browse tools, templates and resources</h3>
                <FilterInput link={"category"}/>
            </Segment>
            <Segment>
                <h3>Search for tools, templates, resources or contributors</h3>
                <SearchInput link={"search"}/>
            </Segment>
        </Container>
    )
}

export default Home
