import React from "react"
import { roles, skills, projectTypes, toolActivityTypes } from '../data-fields'

const ContributorDetail = props => {
  const { contributor } = props

  if (contributor) {
    return (
      <>
        <h2>{contributor.name}</h2>
        <p>{contributor.org}</p>

        {contributor.project_types &&
          <>
            <h4>Project Types</h4>
            <ul>
              {contributor.project_types.map((item,i) => {
                // filter the field obj to get the name matching this id
                return (<li key={i}>{projectTypes.filter(p => p.id == item)[0].name}</li>)
              })}
            </ul>
          </>
        }
        {contributor.roles &&
          <>
            <h4>Roles</h4>
            <ul>
              {contributor.roles.map((item,i) => {
                return (<li key={i}>{roles.filter(p => p.id == item)[0].name}</li>)
              })}
            </ul>
          </>
        }
        {contributor.skills &&
          <>
            <h4>Specialist skills</h4>
            <ul>
              {contributor.skills.map((item,i) => {
                return (<li key={i}>{skills.filter(p => p.id == item)[0].name}</li>)
              })}
            </ul>
          </>
        }
        {contributor.tool_activity_types &&
          <>
            <h4>Types of tools and activities</h4>
            <ul>
              {contributor.tool_activity_types.map((item,i) => {
                return (<li key={i}>{toolActivityTypes.filter(p => p.id == item)[0].name}</li>)
              })}
            </ul>
          </>
        }

      </>
    )
  } else {
    return <p> loading </p>
  }
}

export default ContributorDetail
