import React from "react"
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import { List, ListItem } from 'semantic-ui-react'
import { Link } from "react-router-dom"

const TemplatesList = props => {

  let option = { collection: "templates", storeAs: "templateList" }
  useFirestoreConnect([option])
  const templates = useSelector(state => state.firestore.ordered["templateList"])

  let templatesList
  
  if (templates) {
    // Not expecting to need to make this list unique
    templatesList = templates.map(template => {
      return (
        <ListItem key={template.id} as={Link} to={`/template/${encodeURI(template.name)}`}>
            {template.name}
        </ListItem>
      )
    })
  }

  return (
    <List className="templates content-list padded">
      {templatesList}
    </List>
  )
}

export default TemplatesList
