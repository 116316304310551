import React from "react"
import Linkify from 'react-linkify'
import { Container, Segment, Rating } from 'semantic-ui-react'
import ContributorIcon from '../Contributor/ContributorIcon'
import { toolActivityTypes } from '../data-fields'
import { Link } from "react-router-dom"

const FieldRender = props => {
  const { data, field, title } = props

  const formatItem = (item, field) => {
    let ratingLabel = { pre: "", post: "" }
    switch (field) {
      case "how_well": ratingLabel = { pre: "poorly", post: "well" }; break;
      case "how_easy": ratingLabel = { pre: "hard", post: "easy" }; break;
    }
    switch (field) {
      case "tool_activity_types":
        let output = []
        item.map(item_ => {
          const toolActivityType = toolActivityTypes.filter(p => p.id == item_)[0]
          output.push(toolActivityType ? toolActivityType.name : item_)
        })
        return output.join(", ")
      case "how_well":
      case "how_easy":
        return (
          <div className="rating">
            <label className={`pre ${item === "0" ? "selected" : ""}`}>{ratingLabel.pre}</label>
            <Rating defaultRating={item} maxRating={5} disabled />
            <label className={`post ${item === "5" ? "selected" : ""}`}>{ratingLabel.post}</label>
          </div>
        )
      default:
        return item
    }
  }

  if (data[field].length === 0) return false

  return (
    <Segment>
      <h4>{title}</h4>
      <ul className="content-row">
        {
          data[field].map((item, i) => {
            return (
              <li key={i}>
                <span className="content">
                  <Linkify>
                    {formatItem(item.value, field)}
                  </Linkify>
                </span>
                <span className="contributed-by">
                  <ContributorIcon id={item.contributed_by} />
                </span>
              </li>
            )
          })
        }
      </ul>
    </Segment>
  )
}
export default FieldRender
