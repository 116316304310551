import React from "react"
import { Container, Segment } from 'semantic-ui-react'
import NavBar from "../NavBar"
import ResourceList from "./ResourceList"

const Resources = () => {
  return (
    <Container>
      <NavBar />
      <Segment>
        <h3>Resources</h3>
        <ResourceList />
      </Segment>
    </Container>
  )
}

export default Resources
