import React from "react"
import { Container, Grid, Segment } from 'semantic-ui-react'
import NavBar from "../NavBar"
import { HTML } from './about.html'
import Parser from 'html-react-parser'

const About = () => {
  const html = Parser(HTML)

  return (
    <Container className="about-page" >
      <NavBar />

      <Segment>
        {html}
      </Segment>

      <Segment>
        <Grid className="logos" verticalAlign='middle' centered stackable>
          <Grid.Row columns={3} >
            <Grid.Column textAlign='center'><img src="/fla-logo.jpg" className="logo fla-logo" /></Grid.Column>
            <Grid.Column textAlign='center'><img src="/uq-logo.png" className="logo uq-logo" /></Grid.Column>
            <Grid.Column textAlign='center'><img src="/cdu-logo.png" className="logo cdu-logo" /></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} >
            <Grid.Column textAlign='center'><img src="/coedl-logo.png" className="logo coedl-logo" /></Grid.Column>
            <Grid.Column textAlign='center'><img src="/ila-logo.jpg" className="logo ila-logo" /></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

    </Container>
  )
}

export default About
