export const HTML = `

<h1>Yaale: Tools for language work</h1>

<p>
    <em>Yaale</em> is a project that collates and shares information about the various tools that are useful for language work.
</p>

<p>
    The project aims to identify the tools and templates that language workers find most useful in supporting their daily language activities; as well as in tools that are used occasionally to meet the needs of special events, projects, tasks or users.
</p>

<p>
    Information is being collected from a diverse range of people involved in language projects in order to provide a broad overview of the types of tools available to support local language activities. Information about contributors’ personal skills and
    experience has also been collected to provide background and context to the way the tools are meeting community needs.
</p>

<p>
    <em>Yaale</em> is a growing collection. <a href="mailto:yaale.tools@gmail.com?subject=Yaale">Email First Languages Australia</a> to contribute information about the tools and templates that you find most useful.
</p>
<p>
    <em>‘Yaale’ pronounced ‘yarle’ means speak in Waka Waka. The title has been provided by project researcher Annalee Pope.</em>
</p>

<hr />


  <div className="artwork-credit" style="display:flex;">
    <img src="/5.jpg" alt="Taynto: Meeting Places, by Koorroyarr Arts" class="artwork-credit-thumbnail"/>
    <p>Artwork (detail) - Taynto: Meeting Places, by Koorroyarr Arts (2019)</p>
</div>

<hr />

<p>
    The project is a collaboration between

    <a target="_leaving_gambay" href="https://firstlanguages.org.au/get-involved/contact-your-local-language-group">First Languages Australia, language centres and programs nationally</a>, the

    <a target="_leaving_gambay" href="https://www.uq.edu.au">University of Queensland</a> and

    <a target="_leaving_gambay" href="https://www.cdu.edu.au">Charles Darwin University</a>; with support from the <a target="_leaving_gambay" href="http://www.dynamicsoflanguage.edu.au">Centre of Excellence for the Dynamics of Languages</a> and the

    <a target="_leaving_gambay" href="https://www.arts.gov.au/funding-and-support/indigenous-languages-and-arts-program" title="ILA">Indigenous Languages and Arts program</a>.
</p>

<p>
    The project partners would like to extend our thanks to the individual contributors, each of whom have generously shared their expertise and experience to pave the way for others in the field.
</p>

`
