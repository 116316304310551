import React, { Component } from "react"
import { Link, withRouter } from 'react-router-dom';
import { Card, Button } from 'semantic-ui-react'
import { toolActivityTypes } from '../data-fields'

class FilterInput extends Component {


  handleClick = id => {
    const { link } = this.props
    if (link) this.props.history.push(link+"/"+id)
  }

  render() {
    return (
      <div className="flex-card-container">
          <div className="filter-button">
          {
              toolActivityTypes.map((item, i) => {
                return (
                  <Button
                    basic
                    key={i}
                    onClick={e => this.handleClick(item.id)}>
                      {item.name}
                  </Button>
                )})
          }
          <Button
            basic
            as={Link}
            to={'search'}>
            All
          </Button>

          </div>
      </div>
    )
  }
}
export default withRouter(FilterInput)
